import { Routes, Route, useLocation } from "react-router-dom";
import Conference from "./components/Conference/Conference.jsx";
import Error from "./components/Error404/Error.jsx";
import FirstConferencePage from "./components/Conference/AggreConference/FirstConference.jsx";
import { useEffect } from "react";
import MainPage from "./components/MainPage.jsx";
import Retrite from "./components/Retrite/Retrite.jsx";
import Flirt from './components/Conference/FlirtConference/FlirtConference.jsx'
import Profile from './components/UsersProfiles/Profile.jsx'
// import TechnicalWindow from "./components/TechnicalPage/TechnicalWindow.jsx";

export default function App() {
  // Для прокрутки вверх при переходе на разные страницы сайта
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <>
      <ScrollToTop />

      <Routes>
        {/* <Route path="/" element={<TechnicalWindow />} /> */}
        <Route path="/" element={<MainPage />} />
        <Route path="/conference" element={<Conference />} />
        <Route path="/aggression" element={<FirstConferencePage />} />
        <Route path="/retrite" element={<Retrite />} />
        <Route path="/*" element={<Error />} />
        <Route path="/flirt" element={<Flirt />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </>
  );
}
